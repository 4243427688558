import React, { useMemo } from 'react'
import { MAX_CAROUSEL_ITEM_COUNT } from './common/constants'
import { ANYWHERE_PLACE_ID } from 'constants/search'
import { LOOKING_FOR_SOMETHING_ELSE_CONTENT } from '../LookingForSomethingElse/constants'
import { isManualSortingEnabledFor } from 'selectors/featuresSelectors'
import { OFFER_TYPE_TOUR_V2 } from 'constants/offer'
import OfferCarouselLookingForSomethingElse from '../LookingForSomethingElse/OfferCarouselLookingForSomethingElse'
import OfferCardCarouselSection from 'components/Common/OfferCardCarousel/OfferCardCarouselSection'
import Places from 'constants/places'

const DEFAULT_FILTERS: App.OfferListFilters = {
  offerTypes: ['tour', 'tour_v2'],
  frontPageVisibleOnly: true,
  holidayTypes: ['!Cruises'],
  destinationId: Places.Anywhere.id,
}

interface Props {
  title?: React.ReactNode;
}

function TourOfferCarousel(props: Props) {
  const { title } = props
  const isManualSortingEnabled = isManualSortingEnabledFor('tours')

  const offerListFilters = useMemo((): App.OfferListFilters => {
    if (isManualSortingEnabled) {
      return {
        offerTypes: [OFFER_TYPE_TOUR_V2],
        sortBy: 'vertical-tour',
        limit: MAX_CAROUSEL_ITEM_COUNT,
      }
    }

    return DEFAULT_FILTERS
  }, [isManualSortingEnabled])

  const to = useMemo(() => {
    if (offerListFilters.destinationId && offerListFilters.destinationId !== ANYWHERE_PLACE_ID) {
      const query = {
        destinationId: offerListFilters.destinationId,
        destinationName: offerListFilters.destinationName ?? '',
      }
      return `/search/tours?${new URLSearchParams(query)}`
    }
    return LOOKING_FOR_SOMETHING_ELSE_CONTENT.tours.to
  }, [offerListFilters])

  return (
    <OfferCardCarouselSection
      filters={offerListFilters}
      title={title ?? <>Once-in-a-lifetime <i>tours</i></>}
      allLink="/tours"
      additionalFilters="tourLocations"
      defaultFilter={Places.Anywhere.id}
      tileStyle="card-medium"
    >
      <OfferCarouselLookingForSomethingElse to={to} />
    </OfferCardCarouselSection>
  )
}

export default TourOfferCarousel
