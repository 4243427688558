import React, { useCallback, useContext, useMemo, useState } from 'react'
import LayoutContainer from 'components/Common/LayoutContainer'
import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import Pane from 'components/Common/Pane'
import { ANYWHERE_SEARCH_ITEM } from 'constants/search'
import GeoContext from 'contexts/geoContext'
import { getHomeOfferListParams } from 'home/helpers'
import OfferListCarousel from 'components/OfferList/OfferListCarousel'
import config from 'constants/config'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { encodeSearchParams } from 'lib/search/searchUtils'
import TextButton from 'components/Luxkit/Button/TextButton'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import OfferCarouselLookingForSomethingElse from '../LookingForSomethingElse/OfferCarouselLookingForSomethingElse'
import { mediaQueryDown, mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import styled from 'styled-components'
import useCurrentSiteTakeover from 'hooks/useCurrentSiteTakeover'
import BodyText from 'components/Luxkit/Typography/BodyText'
import SiteTakeoverBackgroundImage from 'components/Common/SiteTakeover/SiteTakeoverBackgroundImage'
import { isAgentHubEnabled } from 'agentHub/selectors/agentHubSelectors'
import FilterChip from 'components/Luxkit/Chips/FilterChip'
import Carousel from 'components/Luxkit/Carousel/Carousel'
import * as Analytics from 'analytics/analytics'
import { DESTINATIONS } from 'constants/config/region'
import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import LineStopwatchIcon from 'components/Luxkit/Icons/line/LineStopwatchIcon'
import LineGlassMartiniIcon from 'components/Luxkit/Icons/line/LineGlassMartiniIcon'
import LineHeartIcon from 'components/Luxkit/Icons/line/LineHeartIcon'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyExperiments } from 'constants/optimizely'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'

const SiteTakeoverBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
`

const CarouselContainer = styled(Pane)`
  position: relative;
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};

  ${mediaQueryUp.desktop} {
    /**
     * This adds room for the search section on all homepages
     */
    padding-bottom: ${rem(100)};
  }
`

const Content = styled(VerticalSpacer)`
  position: relative;
  z-index: 1;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto); 
  gap: ${rem(12)}; 
  justify-content: start;
  align-items: center; 
  ${mediaQueryDown.mobile} {
    grid-template-columns: repeat(2, auto); 
  }
`

const filterOptions = [
  { label: 'Bonus Dining & Drinks', value: 'Dining & Drinks' },
  { label: 'Family Friendly', value: 'Family Friendly' },
  { label: 'Sun & Beach', value: 'Sun & Beach' },
]

const uspsIcons = [
  {
    key: 'limited-time',
    startIcon: <LineStopwatchIcon size={20} colour="highlight-primary-normal"/>,
    label: 'Limited-time',
    labelMobile: 'Limited-time',
    text: 'Limited-time',
  },
  {
    key: 'bonus-inclusions',
    startIcon: <LineGlassMartiniIcon size={20} colour="highlight-primary-normal"/>,
    label: 'Bonus inclusions',
    labelMobile: 'Bonus inclusions',
    text: 'Bonus inclusions',
  },
  {
    key: '24-7-customer-service',
    startIcon: <LinePhoneIcon size={20} colour="highlight-primary-normal"/>,
    label: '24/7 customer service',
    labelMobile: '24/7 customer',
    text: '24/7 customer service',
  },
  {
    key: '8-million-members',
    startIcon: <LineHeartIcon size={20} colour="highlight-primary-normal"/>,
    label: '8 million members',
    labelMobile: '8 million members',
    text: '8 million members',
  },
]

interface Props {
  className?: string;
  overrideTitle?: React.ReactNode;
  overrideOfferList?: App.OfferList;
  hideFilters?: boolean;
}

function HeroOfferCarouselSection(props: Props) {
  const { className, overrideTitle, overrideOfferList, hideFilters = false } = props
  const { currentRegionCode, geoStateName } = useContext(GeoContext)
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>()
  const isMobileScreen = useScreenSizeOnly('mobile')
  const overrideOfferListFetching = !!overrideOfferList?.fetching

  const toggleFilter = useCallback<React.MouseEventHandler<HTMLButtonElement>>((e) => {
    const filter = e.currentTarget.dataset.value!
    const nextFilter = selectedFilter === filter ? undefined : filter
    if (nextFilter) {
      Analytics.trackClientEvent({
        subject: 'click_button',
        action: filter,
        type: 'interaction',
        category: 'homepage',
      })
    }
    setSelectedFilter(nextFilter)
  }, [selectedFilter])

  const filters = useMemo<App.OfferListFilters>(() => {
    return {
      ...getHomeOfferListParams({
        region: currentRegionCode,
        subregion: geoStateName,
      }),
      holidayTypes: selectedFilter ? [selectedFilter] : undefined,
    }
  }, [currentRegionCode, geoStateName, selectedFilter])

  const siteTakeover = useCurrentSiteTakeover()

  const searchUrl = useMemo(() => {
    const defaultQueryParams = {
      searchItem: ANYWHERE_SEARCH_ITEM,
      rooms: [config.search.defaultOccupants],
    }

    const europeanBeachQueryParams = {
      destinationId: DESTINATIONS.europe.destinationId,
      destinationName: DESTINATIONS.europe.displayName,
      rooms: [config.search.defaultOccupants],
      filters: {
        holidayTypes: ['Sun & Beach'],
      },
    }

    const queryParams = siteTakeover?.id === 'europeanbeachbreak' ?
      europeanBeachQueryParams :
      defaultQueryParams

    const query = encodeSearchParams(queryParams)
    return `/search?${query.toString()}`
  }, [siteTakeover?.id])

  const carouselPalette = siteTakeover?.palette ?? 'inverse'
  const title = overrideTitle ?? siteTakeover?.heroCarousel.title ??
    (isAgentHubEnabled ? <>Today's <i>featured</i> escapes</> : <>Today's top <i>exclusive</i> offers</>)
  const subtitle = siteTakeover?.heroCarousel.subtitle
  const takeOverLogo = siteTakeover?.heroCarousel.logo
  const isUrgencySavingUpsPricingHomepageSearch = !!useOptimizelyExperiment(OptimizelyExperiments.croUrgencySavingUpsPricingHomepageSearch)
  const showFilters = !hideFilters && (!siteTakeover || siteTakeover.showHeroFilters)

  return (
    <CarouselContainer paletteType={carouselPalette} className={className}>
      {siteTakeover && <SiteTakeoverBackground>
        <SiteTakeoverBackgroundImage background="hero" />
      </SiteTakeoverBackground>}
      <Content gap={24}>
        <LayoutContainer>
          <VerticalSpacer gap={16}>
            <Group
              direction="horizontal"
              horizontalAlign="space-between"
              verticalAlign="center"
              gap={16}
            >
              <Group direction="horizontal" gap={16} verticalAlign="center">
                {takeOverLogo && takeOverLogo}
                <VerticalSpacer gap={2}>
                  {overrideOfferListFetching ? <TextLoadingBox typography="heading2" width="15ch" /> : <Heading variant="heading2">{title}</Heading>}
                  {!!subtitle && <BodyText variant="large">{subtitle}</BodyText>}
                </VerticalSpacer>
              </Group>
              <CSSBreakpoint min="tablet">
                <Group direction="horizontal" gap={16} verticalAlign="center">
                  {showFilters && !overrideOfferListFetching && <CSSBreakpoint min="desktop">
                    <Group direction="horizontal" gap={8}>
                      {filterOptions.map(option =>
                        <FilterChip
                          key={option.value}
                          size="medium"
                          selected={option.value === selectedFilter}
                          data-value={option.value}
                          onClick={toggleFilter}
                        >
                          {option.label}
                        </FilterChip>,
                      )}
                    </Group>
                  </CSSBreakpoint>}
                  <TextButton
                    kind="tertiary"
                    to={searchUrl}
                  >
                    View all
                  </TextButton>
                </Group>
              </CSSBreakpoint>
            </Group>
            {showFilters && !overrideOfferListFetching && <CSSBreakpoint max="tablet">
              <Carousel gap={8} snap="start">
                {filterOptions.map(option =>
                  <FilterChip
                    key={option.value}
                    size="medium"
                    selected={option.value === selectedFilter}
                    data-value={option.value}
                    onClick={toggleFilter}
                  >
                    {option.label}
                  </FilterChip>,
                )}
              </Carousel>
            </CSSBreakpoint>}
            {isUrgencySavingUpsPricingHomepageSearch && <Grid>
              {uspsIcons.map(icon => (
                <BodyTextBlock key={icon.key} startIcon={icon.startIcon} variant="large" weight="normal" colour="neutral-two">{isMobileScreen ? icon.labelMobile : icon.text} </BodyTextBlock>
              ))}
            </Grid>}
          </VerticalSpacer>
        </LayoutContainer>
        <OfferListCarousel
          overrideOfferList={overrideOfferList}
          filters={filters}
          tileStyle="card-medium"
        >
          <OfferCarouselLookingForSomethingElse to={searchUrl} />
        </OfferListCarousel>
        <CSSBreakpoint max="mobile">
          <TextButton
            kind="tertiary"
            to={searchUrl}
          >
            View all
          </TextButton>
        </CSSBreakpoint>
      </Content>
    </CarouselContainer>
  )
}

export default React.memo(HeroOfferCarouselSection)
